<template>
  <footer
    class="max-w-screen-content gap-sm laptop:flex-row laptop:gap-xxl max-laptop:pb-xl flex flex-col items-center justify-center"
  >
    <div class="flex h-min items-center justify-center">
      <GLink :to="{ name: 'cgu' }">Conditions générales d'utilisation</GLink>
    </div>
    <div class="flex h-min items-center justify-center">
      <GLink :to="{ name: 'confidentialite' }"
        >Politique de confidentialité</GLink
      >
    </div>
    <div class="flex h-min items-center justify-center">
      <GLink to="https://info.agenda.org/"> Nous contacter </GLink>
    </div>
  </footer>
</template>
